import array = require("dojo/_base/array");
import { Color } from "Everlaw/ColorUtil";
import Base = require("Everlaw/Base");
import { ColorTokens } from "design-system";
import Core = require("Everlaw/Core");
import Arr = require("Everlaw/Core/Arr");
import Cmp = require("Everlaw/Core/Cmp");
import Is = require("Everlaw/Core/Is");
import Document = require("Everlaw/Document"); // circular dependency - use for types only
import Perm = require("Everlaw/PermissionStrings");
import Project = require("Everlaw/Project");
import Rest = require("Everlaw/Rest");
import Task = require("Everlaw/Task");
import Type = require("Everlaw/Type");
import Typed = require("Everlaw/Typed");
import User = require("Everlaw/User");
import { Canonical } from "Everlaw/Canonical";
import { ElevatedRoleConfirm } from "Everlaw/ElevatedRoleConfirm";

// This category numbering imposes a precedence order on fields with the same
// name: fields with higher category numbers take precedence.
export enum Category {
    SYSTEM = -2,
    CONFLICT = -1,
    NATIVE_EXTRACTED,
    ORIGINAL,
    VIRTUAL,
    SEMANTIC,
    ALIAS,
    DOC_TYPE_ALIAS,
}

// Maps names to arrays of fields with that name, ordered by category precedence.
// Includes only SEMANTIC, ALIAS, and DOC_TYPE_ALIAS.
const primaryCandidateFields: { [name: string]: Field[] } = {};
const virtualFields: { [name: string]: Field } = {};
const systemFields: { [name: string]: SystemField } = {};
const originalFields: { [name: string]: Field } = {};
// Maps SEMANTIC fields (by id)
const conflictFields: { [semanticFieldId: string]: ConflictField[] } = {};

/**
 * Returns the primary field (highest precedence category) with the specified name.
 */
export function fieldByName(name: string) {
    return name in primaryCandidateFields ? primaryCandidateFields[name][0] : null;
}

export function originalFieldByName(name: string) {
    return originalFields[name] || null;
}

/**
 * Returns all fields (that are primary candidates) with the specified name.
 */
export function allFieldsByName(name: string) {
    return primaryCandidateFields[name] || [];
}

/**
 * Returns all fields in a category
 */
export function fieldsByCategory(category: Category) {
    return Base.get(Field).filter((mf) => mf.category === category);
}

/**
 * Returns all editable fields
 */
export function getEditableFields() {
    return fieldsByCategory(Category.SEMANTIC).filter((mf) => (<SemanticField>mf).isEditable());
}

/**
 * Returns the field with the given name and category. The category should be one of the primary
 * candidate categories ("SEMANTIC", "ALIAS", or "DOC_TYPE_ALIAS"), or "VIRTUAL".
 */
export function fieldByNameAndCategory(name: string, category: Category) {
    if (category === Category.VIRTUAL) {
        return virtualFields[name];
    }

    const opts = primaryCandidateFields[name];
    if (opts) {
        for (let i = 0; i < opts.length; i++) {
            if (opts[i].category === category) {
                return opts[i];
            }
        }
    }
    return null;
}

/**
 * Returns the primary metadata fields in (optionally) sorted order.
 * If all is true, returns all (SEMANTIC, ALIAS, and DOC_TYP_ALIAS) metadata fields; otherwise,
 * only visible.
 */
export function primaryFields(all = false, sort = true) {
    const fields = Object.values(primaryCandidateFields).map((fieldsOfName) => fieldsOfName[0]);
    const wantedFields = all ? fields : fields.filter((f) => f.visible);
    return sort ? Arr.sort(wantedFields) : wantedFields;
}

/**
 * Will include Everlaw's Family Date field if appropriate. See
 * {@link includeEverlawFamilyDateField} details.
 */
export function primaryFieldsPlusFamilyDate(all = false, sort = true): Field[] {
    const fields = primaryFields(all, false);
    if (includeEverlawFamilyDateField(all)) {
        fields.push(getFamilyDateField());
    }
    return sort ? Arr.sort(fields) : fields;
}

export class ConfirmedActions {
    @ElevatedRoleConfirm("creating or editing an alias field")
    static createOrEditAlias(
        alias: Field,
        name: string,
        aliasOf: Field[],
        callback?: (f: Field) => void,
        error?: Rest.Callback,
    ) {
        Rest.post("metadata/createOrEditAliasField.rest", {
            name: name,
            aliasOf: aliasOf.map((f) => f.id),
            id: alias ? alias.id : null,
        }).then(
            (data) => {
                const res = Base.set(Field, data);
                callback && callback(res);
            },
            (e) => {
                error && error(e);
                throw e;
            },
        );
        ga_event("Metadata", "Create/Edit Alias");
    }

    @ElevatedRoleConfirm("removing a alias field")
    static removeField(field: Field, callback?: (f: Field) => void, error?: Rest.Callback) {
        if (!(field instanceof AliasField)) {
            return;
        }
        Rest.post("metadata/removeAliasField.rest", { id: field.id }).then(
            () => {
                _removeField(field);
                callback && callback(field);
            },
            (e) => {
                error && error(e);
                throw e;
            },
        );
    }
}

export function _removeField(field) {
    if (field.name && primaryCandidateFields[field.name]) {
        Arr.remove(primaryCandidateFields[field.name], field);
        if (!primaryCandidateFields[field.name].length) {
            delete primaryCandidateFields[field.name];
        }
    }
    Base.remove(field);
    Base.publish(field.getAliases());
}

export function getSystemField(name: System) {
    return systemFields[name];
}

export const fieldColor = Color.fromEverColor(ColorTokens.OBJECT_METADATA_FIELD);

export type FieldId = number & Base.Id<"MetadataField">;

export interface FieldParams extends Typed.FieldParams {
    category: string;
    visible: boolean;
}

export class Field extends Typed.Field {
    get className() {
        return "MetadataField";
    }
    override id: FieldId;
    category: Category;
    override type: Type.FieldType;
    override visible: boolean;

    constructor(params: FieldParams, fromSubclass?: boolean) {
        // CATEGORY_TO_CLASS is safe to hoist so long as no instance or subclass of Field
        // is instantiated before CATEGORY_TO_CLASS is declared.
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        if (!fromSubclass && params.category in CATEGORY_TO_CLASS) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            return new CATEGORY_TO_CLASS[params.category](params, true);
        }
        super(params);
    }

    override _mixin(params: FieldParams) {
        const existing = Base.get(Field, this.id);
        const oldName = existing ? existing.name : null;
        super._mixin(params);
        if (this.name && primaryCandidateFields[this.name]) {
            Arr.remove(primaryCandidateFields[this.name], this);
        }
        if (existing && oldName !== this.name && primaryCandidateFields[oldName]) {
            Arr.remove(primaryCandidateFields[oldName], this);
            if (!primaryCandidateFields[oldName].length) {
                delete primaryCandidateFields[oldName];
            }
        }
        this.category = (<any>Category)[params.category];
        if (this instanceof VirtualField) {
            virtualFields[this.name] = this;
        } else if (this instanceof SystemField) {
            systemFields[this.name] = this;
        } else if (this instanceof ConflictField) {
            const conflictFieldList = conflictFields[this.semanticFieldId] || [];
            conflictFieldList.push(this);
            conflictFields[this.semanticFieldId] = conflictFieldList;
        } else if (this.isOriginal()) {
            originalFields[this.name] = this;
        } else {
            primaryCandidateFields[this.name] = primaryCandidateFields[this.name] || [];
            Arr.insertSorted(primaryCandidateFields[this.name], this);
        }
    }

    /**
     * The "termName" part of the EQL.
     */
    termName() {
        return "metadata";
    }

    getColor() {
        return fieldColor;
    }

    override compare(other: Field) {
        return fieldCompare(this, other);
    }

    /**
     * Returns an array of fields shadowed by this field on the given doc. Covering is a transitive
     * property. Example:
     * -> B directly covers C, D
     * -> C directly covers E
     * -> D directly covers F
     * Then B covers C, D, E, and F. However, the results are returned in depth first traversal
     * order so that B.coversFields(someDoc) -> [C, E, D, F]. This is called 'alias resolution
     * order' elsewhere.
     *
     * Implementation note: since for any field f, if f.docValue() == null then any field covered
     * by f also has no value. As a result fields without a value on doc should be included, since
     * they
     * (and all their covered children) can easily be filtered out later.
     *
     * <b>Most subclasses should override this.</b>
     */
    coversFields(doc: Document): Field[] {
        return [];
    }

    covers() {
        return null;
    }

    isCoveredBy(): AliasingField {
        return null;
    }

    categoryDisplay(value: Value): string {
        const cat = Category[this.category];
        const source = value.originalSource;
        if (!source) {
            return cat;
        }
        return source.replace("_", " ");
    }

    docValue(doc: Document) {
        return doc.metadata[this.id];
    }

    // is this the primary metadata field with this name?
    isPrimary() {
        return this.equals(fieldByName(this.name));
    }

    // If this field's value is changed, or deleted, this function publishes
    // all the other fields that might depend on it.
    notifyOnChange() {
        Base.publish(this);
        // republish all the aliases that alias this value
        array.forEach(fieldsByCategory(Category.ALIAS), (a: AliasingField) => {
            if (this.findIn(a.getAliases()) !== -1) {
                Base.publish(a);
            }
        });
    }

    isOriginal() {
        return this.category === Category.ORIGINAL;
    }

    setVisible(visible: boolean, callback?: (f: Field) => void, error?: Rest.Callback) {
        if (this.visible === visible) {
            // Even if this actual visible state isn't getting changed, the ability to change it
            // might now be restricted (due to a masking alias field getting created).
            Base.publish(this);
            callback && callback(this);
            return;
        }
        Rest.post("metadata/setVisibility.rest", {
            id: this.id,
            visible: visible,
        }).then(
            () => {
                this.visible = visible;
                Base.publish(this);
                callback && callback(this);
            },
            (e) => {
                error && error(e);
                throw e;
            },
        );
    }

    isRedactable() {
        return (
            this.category === Category.SEMANTIC
            || this.category === Category.ALIAS
            || this.category === Category.DOC_TYPE_ALIAS
        );
    }

    valueFromJson(json: string): Value {
        return new Value(this, this.getType().fromJsonValue(JSON.parse(json)));
    }

    getDatavisProperty(): string {
        return "metadata";
    }
    getSortName(): string {
        return "metadata";
    }
    getSortSignature(descending: boolean): [any[], boolean] {
        return [[this.getSortName(), this.id], !!descending];
    }
    isEditable(): boolean {
        return false;
    }
    getIconClass(): string | undefined {
        return null;
    }
    description(): string | undefined {
        return null;
    }
    getRedactionTooltip(): string {
        return "Redact this field";
    }
}

export enum VirtualFieldKind {
    RECIPIENTS = "Recipients",
    PARTIES = "Parties",
    FAMILY_DATE = "Family Date",
}

/**
 * This should mirror the ids of the VirtualMetadataField.Kind enum in the backend.
 */
export enum VirtualFieldId {
    RECIPIENTS = -1,
    PARTIES = -2,
    FAMILY_DATE = -3,
}

/**
 * Represents the {@code VirtualMetadataField} of the server.
 */
export class VirtualField extends Field {
    static NAME_TO_ID: { [x: string]: number } = {};

    constructor(
        id: VirtualFieldId,
        name: string,
        private readonly desc: string,
        fieldType = Type.ADDRESS_LIST.name,
    ) {
        super(
            {
                type: fieldType,
                name,
                id,
                category: "VIRTUAL",
                visible: true,
            },
            true,
        );

        VirtualField.NAME_TO_ID[name] = id;
    }

    static generate(id: number, kind: VirtualFieldKind, desc: string): void {
        Base.add(new VirtualField(id, kind, desc));
    }

    static of(name: string): VirtualField {
        return Base.get(VirtualField, VirtualField.NAME_TO_ID[name]);
    }

    public override description() {
        return this.desc;
    }

    override termName() {
        return "virtualMetadata";
    }

    override getIconClass(): string {
        return "bolt-filled-blue-16";
    }
}

VirtualField.generate(VirtualFieldId.RECIPIENTS, VirtualFieldKind.RECIPIENTS, "To, Cc, and Bcc");
VirtualField.generate(VirtualFieldId.PARTIES, VirtualFieldKind.PARTIES, "From, To, Cc, and Bcc");

/**
 * This field is Everlaw's "Family Date" field. User-created fields (ALIAS or SEMANTIC) named
 * "Family Date" will take precedence over this field. It is mainly a wrapper for the
 * familyDateValue attribute of a Document which allows us include it in the metadata table in the
 * review window.
 */
export class FamilyDateVirtualField extends VirtualField {
    static readonly CUSTOM_FAMILY_DATE = "Custom family date";

    constructor(id: VirtualFieldId, kind: VirtualFieldKind, desc: string) {
        super(id, kind, desc, Type.DATE_TIME.name);
    }
    override docValue(doc: Document): Value {
        return doc.familyDateValue;
    }
    override getDatavisProperty(): string {
        return "familyDate";
    }
    override getSortName(): string {
        return this.getDatavisProperty();
    }
    override getSortSignature(descending: boolean): [any[], boolean] {
        return [[this.getSortName()], !!descending];
    }
    override getIconClass(): string {
        return "bolt-filled-blue-16";
    }
    override getRedactionTooltip(): string {
        return "Redact parent primary date to redact family date";
    }
    // Everlaw's "Family Date" is primary when there isn't a user created "Family Date" field visible on the project.
    override isPrimary() {
        return includeEverlawFamilyDateField(false);
    }
    static registerWithBase(): void {
        if (!getFamilyDateField()) {
            Base.add(
                new FamilyDateVirtualField(
                    VirtualFieldId.FAMILY_DATE,
                    VirtualFieldKind.FAMILY_DATE,
                    "The parent document's primary date value; if there is no parent, "
                        + "then the document's primary date",
                ),
            );
        }
    }
}

FamilyDateVirtualField.registerWithBase();

export function fieldCompare(x: Field | RawField, y: Field | RawField) {
    // First, compare names case-insensitively, then go to category precedence,
    // case-sensitive name comparison and finally id.
    const thisId = x instanceof Field ? x.id : 0;
    const otherId = y instanceof Field ? y.id : 0;
    return (
        Cmp.strCI(x.name, y.name)
        || y.category - x.category
        || x.name.localeCompare(y.name)
        || thisId - otherId
    );
}

export class RawField extends Base.Object {
    get className() {
        return "RawField";
    }
    name: string;
    override id: string;
    category = Category.NATIVE_EXTRACTED;
    public targets: string[];

    constructor(params: any) {
        super(params);
        this._mixin(params);
    }

    override _mixin(params: any) {
        Object.assign(this, params);
        this.name = <string>this.id;
    }

    override display() {
        return this.name;
    }

    override compare(other: RawField) {
        return fieldCompare(this, other);
    }
}

export class SystemFieldDefinition extends Base.Object {
    get className() {
        return "SystemFieldDefinition";
    }
    // This is null until a request to update this definition is made - this is because the definitions
    // exist before the metadata fields are loaded, so there is no easy way to know what the initial
    // definition is.  Instead, we store it when the first request to update the defintion is made.
    private _original: Field[] = null;

    constructor(
        public name: System,
        public category: SystemCategory,
    ) {
        super({ id: name });
    }

    getMetadataField() {
        return getSystemField(this.name);
    }

    getAliases() {
        const f = this.getMetadataField();
        return f ? f.getAliases() : [];
    }

    override compare(other: SystemFieldDefinition) {
        return (
            Cmp.str(this.category.name, other.category.name)
            || Cmp.str(this.display(), other.display())
        );
    }

    updated() {
        return this._original != null && !Core.equals(this._original, this.getAliases());
    }

    saveOriginal() {
        // after reindexing or on first load, we want to snapshot the original aliases
        this._original = this.getAliases();
    }

    override display() {
        return this.name;
    }

    addField(newFields: Field[], callback?: () => void, error?: Rest.Callback) {
        if (this._original === null) {
            this.saveOriginal();
        }
        Rest.post("metadata/addToSystemField.rest", {
            aliasOf: newFields.map((f) => f.id),
            fieldName: this.name,
        }).then(
            (data) => {
                Base.set(Field, data);
                Base.publish(this);
                callback && callback();
            },
            (e) => {
                error && error(e);
                throw e;
            },
        );
    }

    removeField(mdField: Field) {
        if (this._original === null) {
            this.saveOriginal();
        }
        const field = this.getMetadataField();
        if (field) {
            field.removeAlias(mdField, Base.publish.bind(this, this));
        }
    }
}

export { Canonical };

/**
 * Matches CanonicalField.java.
 */
export enum CanonicalTypeByField {
    // TODO: consolidate Canonical fields - some are grouped by type in DatavisTab.ts
    ALL_CUSTODIANS = "Text",
    ALL_PATHS = "Text",
    APPLICATION = "Text",
    ATTACHMENT_IDS = "Text",
    ATTACHMENT_NAMES = "Text",
    AUTHOR = "Text",
    BCC = "AddressList",
    BEGIN_FAMILY = "Bates",
    CC = "AddressList",
    CHAT_CONTRIBUTORS = "Text",
    CHAT_CONVERSATION_ID = "Text",
    CHAT_CONVERSATION_INDEX = "Number",
    CONFIDENTIALITY = "Text",
    CUSTODIAN = "Text",
    CUSTOM_ALL_CUSTODIANS = "Text",
    DATASET = "Text",
    DATA_TYPE = "Text",
    DATE = "DateTime",
    DATE_ACCESSED = "DateTime",
    DATE_CREATED = "DateTime",
    DATE_MODIFIED = "DateTime",
    DATE_PRINTED = "DateTime",
    DATE_RECEIVED = "DateTime",
    DATE_SAVED = "DateTime",
    DATE_SENT = "DateTime",
    DOCUMENT_TYPE = "Text",
    ENCRYPTED = "Text",
    END_DATE = "DateTime",
    END_FAMILY = "Bates",
    ENDORSED_TEXT = "Text",
    EXTENSION = "Text",
    FAMILY = "Text",
    FAMILY_RANGE = "Text",
    FILENAME = "Text",
    FILE_PATH = "Text",
    FROM = "AddressFrom",
    GPS_LATITUDE = "Text",
    GPS_LONGITUDE = "Text",
    HASH_VALUE = "Text",
    HAS_OCR = "Text",
    HIDDEN_CONTENT = "Text",
    IN_REPLY_TO = "Text",
    LANGUAGES = "Text",
    MD5_HASH = "MD5",
    MESSAGE_ID = "Text",
    MIME_TYPE = "Text",
    ORIGINAL_FILENAME = "Text",
    ORIGINAL_PATH = "Text",
    OTHER_BATES = "Bates",
    OTHER_CUSTODIANS = "Text",
    PARENT_ID = "Bates",
    PLACEHOLDER = "Text",
    PLACEHOLDER_TEXT = "Text",
    PRIVILEGE_TYPE = "Text",
    PRODUCED_FROM = "Bates",
    REDACTED = "Text",
    REDACTION_STAMPS = "Text",
    REDACTION_STAMP_DETAILS = "Text",
    SHA1_HASH = "SHA1",
    SPEAKER_NOTES = "Text",
    SPLIT_FROM = "Bates",
    START_DATE = "DateTime",
    SUBJECT = "Text",
    TAGS = "Text",
    TICK_NO = "Text",
    TITLE = "Text",
    TO = "AddressList",
    TRACK_CHANGES = "Text",
    TRANSLATION_OF = "Text",
}

/**
 * Returns a list of placeholder Field objects for all canonical fields.
 * TODO: Use a different type than Field to store these placeholder objects.
 */
export function getListOfCanonicalFields(filter: (c: Canonical) => boolean = () => true): Field[] {
    const fields = [];
    let idCount = 1;
    for (const field in Canonical) {
        filter(Canonical[field])
            && fields.push(
                new Field({
                    // Float values are used for the ids to avoid collisions with real metadata fields.
                    id: idCount + 0.1,
                    name: Canonical[field],
                    category: "SEMANTIC",
                    type: CanonicalTypeByField[field],
                    visible: true,
                }),
            );
        idCount += 1;
    }
    return fields;
}

export function addCanonicalFields() {
    getListOfCanonicalFields().forEach((f) => Base.add(f));
}

export function getListOfIncludedCanonicalFields(): Field[] {
    // These fields, while common in incoming processed data, can be confusing in other contexts
    // because they represent review work, processing flag, or VPC specific information
    const excluded: Canonical[] = [
        Canonical.TICK_NO,
        Canonical.ENDORSED_TEXT,
        Canonical.ORIGINAL_PATH,
        Canonical.ORIGINAL_FILENAME,
        Canonical.OTHER_CUSTODIANS,
        Canonical.PLACEHOLDER_TEXT,
        Canonical.PRIVILEGE_TYPE,
        Canonical.REDACTED,
        Canonical.REDACTION_STAMPS,
        Canonical.REDACTION_STAMP_DETAILS,
        Canonical.HAS_OCR,
        Canonical.TRANSLATION_OF,
        Canonical.DATA_TYPE,
        Canonical.SPLIT_FROM,
        Canonical.ENCRYPTED,
        Canonical.PRODUCED_FROM,
        Canonical.CONFIDENTIALITY,
        Canonical.LANGUAGES,
    ];
    const excludedSet = new Set();
    excluded.forEach((c) => excludedSet.add(c));
    return getListOfCanonicalFields((c) => !excludedSet.has(c));
}

// A lazy-initialized map of Canonical string value to Canonical key
let canonicalValueToKey: { [value: string]: keyof typeof Canonical };
function getCanonicalValueToKeyMap(): { [value: string]: keyof typeof Canonical } {
    if (!canonicalValueToKey) {
        canonicalValueToKey = {};
        Object.keys(Canonical).forEach((k: string) => {
            const key = k as keyof typeof Canonical;
            canonicalValueToKey[Canonical[key].valueOf()] = key;
        });
    }
    return canonicalValueToKey;
}

/**
 * Given a Canonical value, returns the type associated with that Canonical field
 */
export function canonicalTypeName(name: string): string {
    return CanonicalTypeByField[getCanonicalValueToKeyMap()[name]] || "Text";
}

/**
 * Parameters required for adding a new semantic field to the project
 */
export interface AddFieldParams {
    name: string;
    typeName: string;
}

/**
 * Filter out add field params for semantic metadata fields which already exist.
 */
export function filterOutExistingSemantic(params: AddFieldParams[]): AddFieldParams[] {
    const fieldsInProjectSet = new Set(
        Base.get(Field)
            .filter((f) => f instanceof SemanticField)
            .map((f) => f.name),
    );
    return params.filter((p) => !fieldsInProjectSet.has(p.name));
}

/**
 * Add new semantic fields to the project
 * Used to manually add fields which have not been added automatically due to
 * document processing, copying settings from another project, etc
 */
export function createSemanticFields(
    params: AddFieldParams | AddFieldParams[],
    editable = false,
): Promise<SemanticField[]> {
    params = Arr.wrap(params);
    return Rest.post("metadata/createSemanticFields.rest", {
        names: params.map((p) => p.name),
        typeNames: params.map((p) => p.typeName),
        editable: editable,
    }).then((data: SemanticField[]) => Base.set(SemanticField, data));
}

/**
 * Matches SystemField.java
 */
export enum System {
    Attachments = "Attachments",
    Author = "Author",
    Bcc = "Bcc",
    Cc = "Cc",
    ChatConversationId = "ChatConversationId",
    ChatConversationIndex = "ChatConversationIndex",
    Custodian = "Custodian",
    Custodians = "Custodians",
    Dataset = "Dataset",
    DateSent = "DateSent",
    ExactDuplicates = "ExactDuplicates",
    Filenames = "Filenames",
    FilePath = "FilePath",
    From = "From",
    FullFilename = "FullFilename",
    InReplyTo = "InReplyTo",
    MessageId = "MessageId",
    ParentId = "ParentId",
    References = "References",
    Subject = "Subject",
    Title = "Title",
    To = "To",
    Versions = "Versions",
}

export class SystemCategory {
    defns: SystemFieldDefinition[];
    constructor(
        public name: string,
        defNames: System[],
        // either the task name or a function that creates a task
        public task: string = null,
    ) {
        this.defns = defNames.map((name) => new SystemFieldDefinition(name, this));
    }
    hasTask() {
        return this.task !== null;
    }
    runTask(project: Project) {
        if (this.hasTask()) {
            const url = `tasks/${this.task}.rest`;
            const content = { projectId: project.id };
            Task.createTask(url, content);
        }
    }
    dirty() {
        return this.defns.some((f) => f.updated());
    }
}

export module SystemCategories {
    export const ATTACHMENTS = new SystemCategory(
        "Attachment grouping",
        [System.Attachments],
        "attachmentGroupsReindex",
    );
    export const VERSIONS = new SystemCategory(
        "Versions",
        [System.Versions],
        "versionGroupsReindex",
    );
    export const DEDUPLICATION = new SystemCategory(
        "Deduplication",
        [System.ExactDuplicates],
        "exactDupeGroupsReindex",
    );
    export const FILENAMES = new SystemCategory(
        "File names or extensions",
        [System.Filenames],
        "fileNamesReindex",
    );
    export const CHAT_CONVERSATIONS = new SystemCategory(
        "Chat conversations",
        [System.ChatConversationId, System.ChatConversationIndex, System.ParentId],
        "chatConversationGroupsReindex",
    );
    export const EMAIL_THREADING = new SystemCategory("Email threading", [
        System.From,
        System.To,
        System.Cc,
        System.Bcc,
        System.DateSent,
        System.Subject,
        System.MessageId,
        System.References,
        System.InReplyTo,
    ]);
    export const EDOC = new SystemCategory("Edoc specific fields", [System.Title, System.Author]);

    export const FILEPATH = new SystemCategory(
        "File path fields",
        [System.FilePath, System.Custodian, System.Custodians, System.Dataset, System.FullFilename],
        "pathReindex",
    );

    // Note that this determines the order of the Metadata Project Settings.
    export const ALL = [
        ATTACHMENTS,
        VERSIONS,
        DEDUPLICATION,
        FILENAMES,
        CHAT_CONVERSATIONS,
        EMAIL_THREADING,
        EDOC,
        FILEPATH,
    ];
    ALL.forEach((cat) => {
        cat.defns.forEach(Base.add);
    });
}

function coversFieldsUtil(covered: Field[], doc: Document) {
    return Arr.flat<Field>(covered.map((f) => [f].concat(f.coversFields(doc))));
}

export class ConflictField extends Field {
    semanticFieldId: FieldId;
    override coversFields(doc: Document): Field[] {
        // Conflict fields only cover the original fields they derive from.
        return doc.getOriginalCounterparts(this);
    }
}

export class SemanticField extends Field {
    private editable: boolean;

    conflictFields() {
        return conflictFields[this.id] || [];
    }
    override isCoveredBy(): AliasingField {
        const field =
            fieldByNameAndCategory(this.name, Category.DOC_TYPE_ALIAS)
            || fieldByNameAndCategory(this.name, Category.ALIAS);
        return field as AliasingField;
    }
    override coversFields(doc: Document): Field[] {
        // Semantic fields cover the Original fields they derive from, and the Conflict fields
        // they are associated with.
        return doc
            .getOriginalCounterparts(this)
            .concat(coversFieldsUtil(this.conflictFields(), doc));
    }
    setEditable(state: boolean, onCompletion: (success) => void = null, removeUserValues = false) {
        Rest.post("metadata/setEditable.rest", {
            id: this.id,
            editable: state,
            removeUserValues: removeUserValues,
        }).then((result) => {
            this.editable = state;
            onCompletion && onCompletion(!!result);
        });
    }
    override isEditable(): boolean {
        return this.editable;
    }
}

export class AliasingField extends Field {
    /**
     * Internally, we must keep these sorted according to the corresponding fields' sort.
     */
    protected aliases: FieldId[];
    protected sorted = false;
    override _mixin(params: any) {
        super._mixin(params);
        this.sorted = false;
    }
    /**
     * Returns a sorted array of all aliases.
     */
    getAliases() {
        const fields = Base.get(Field, this.aliases);
        // Only sort the aliases once, on first request.
        if (!this.sorted) {
            Arr.sort(fields);
            this.aliases = fields.map((f) => f.id);
            this.sorted = true;
        }
        return fields;
    }
    /**
     * Returns the first non-null Metadata.Value from the highest-category field.
     */
    override docValue(doc: Document) {
        let cat = -1;
        let val: Value = null;
        array.forEach(this.getAliases(), function (mf) {
            const oneVal = mf.docValue(doc);
            if (mf.category > cat && oneVal) {
                cat = mf.category;
                val = oneVal;
            }
        });
        return val;
    }
    /**
     * Returns true if this AliasingField aliases the provided possiblyAliasedField.
     */
    aliasesField(possiblyAliasedField: Field): boolean {
        return this.getAliases().some(
            (aliasedField) => aliasedField.id === possiblyAliasedField.id,
        );
    }
}

export class AliasField extends AliasingField {
    /**
     * These fields need no further sorting because the order of aliased fields is intentional.
     */
    override _mixin(params: any) {
        super._mixin(params);
        this.sorted = true;
    }
    setAliases(aliasOf: Field[], callback?: (f: Field) => void, error?: Rest.Callback) {
        ConfirmedActions.createOrEditAlias(this, this.name, aliasOf, callback, error);
    }
    addAlias(field: Field, callback?: (f: Field) => void, error?: (f: any) => void) {
        const a = this.getAliases();
        if (field.findIn(a) === -1) {
            this.setAliases(a.concat(field), callback, error);
        } else {
            error && error(this);
        }
    }
    removeAlias(field: Field, callback?: (f: Field) => void, error?: (f: any) => void) {
        const aliases = this.getAliases();
        if (Arr.remove(aliases, field)) {
            this.setAliases(aliases, callback, error);
        } else {
            error && error(this);
        }
    }
    remove(callback?: (f: Field) => void, error?: Rest.Callback) {
        return ConfirmedActions.removeField(this, callback, error);
    }
    override coversFields(doc: Document) {
        return coversFieldsUtil(this.getAliases(), doc);
    }
    override isEditable(): boolean {
        const underlyingField = fieldByNameAndCategory(
            this.name,
            Category.SEMANTIC,
        ) as SemanticField;
        return underlyingField ? underlyingField.isEditable() : false;
    }
    override getAliases(): Field[] {
        return Base.get(Field, this.aliases);
    }
    isAlphabeticallySorted(): boolean {
        const currentAliasedFields = this.getAliases();
        return currentAliasedFields.every((field, i) => {
            return i === 0 || Cmp.str(currentAliasedFields[i - 1].name, field.name) <= 0;
        });
    }
}

/**
 * Manners in which DocumentTypes can be grouped. In order to be used for DocTypeAliasMetadataField,
 * implementations should also contain the static methods defined in GroupingClassType below.
 */
export interface DocumentTypeGroup {
    name: string;
    descriptiveName: string;
    docTypes: string[];
}

/**
 * Analogous to the groupings defined in PrimaryDateDocTypeGroup.java. Uses a class instead
 * of an enum because TypeScript enums do not support all of the desired functionalities.
 */
export class PrimaryDateDocTypeGroup implements DocumentTypeGroup {
    private static docTypeToGroup = new Map<string, PrimaryDateDocTypeGroup>();

    /**
     * These four instances should mirror those outlined in the enum in
     * PrimaryDateDocTypeGroup.java. They feature docTypes as defined within Document.ts.
     */
    static readonly MULTI_MEDIA_GROUP = new PrimaryDateDocTypeGroup(
        "MULTI_MEDIA_GROUP",
        "Audio/video/image files",
        ["Audio", "Video", "Image"],
    );

    static readonly CALENDAR_GROUP = new PrimaryDateDocTypeGroup(
        "CALENDAR_GROUP",
        "Calendar items",
        ["Calendar"],
    );

    static readonly MESSAGE_GROUP = new PrimaryDateDocTypeGroup(
        "MESSAGE_GROUP",
        "Email/SMS/chat file types",
        ["Email", "Chat"],
    );

    // Default group, for any docType not explicitly specified in another group
    static readonly OTHER_GROUP = new PrimaryDateDocTypeGroup(
        "OTHER_GROUP",
        "All other file types",
        [],
    );

    // Order in which the groups appear in the metadata tab
    static readonly ALL = [
        PrimaryDateDocTypeGroup.MULTI_MEDIA_GROUP,
        PrimaryDateDocTypeGroup.MESSAGE_GROUP,
        PrimaryDateDocTypeGroup.CALENDAR_GROUP,
        PrimaryDateDocTypeGroup.OTHER_GROUP,
    ];

    private constructor(
        public readonly name: string,
        public readonly descriptiveName,
        public readonly docTypes: string[],
    ) {
        this.docTypes.forEach((type) => PrimaryDateDocTypeGroup.docTypeToGroup.set(type, this));
    }

    public static groupByName(groupName: string): PrimaryDateDocTypeGroup {
        let matchedGroup: PrimaryDateDocTypeGroup = null;
        PrimaryDateDocTypeGroup.ALL.forEach((group) => {
            if (group.name === groupName) {
                matchedGroup = group;
            }
        });
        if (matchedGroup == null) {
            throw new Error(
                `Illegal argument passed to instanceFor(): ${groupName} does not correspond to any instance 
                of the enum PrimaryDateDocTypeGroup.`,
            );
        }
        return matchedGroup;
    }

    public static getGroup(docType: string): PrimaryDateDocTypeGroup {
        const group = PrimaryDateDocTypeGroup.docTypeToGroup.get(docType);
        return Is.defined(group) ? group : PrimaryDateDocTypeGroup.OTHER_GROUP;
    }
}

export enum DocTypeAlias {
    PRIMARY_DATE = "Primary Date",
}

/** This type describes the static capabilities of classes that implement DocumentTypeGroup. */
type GroupingClassType = { getGroup(docType: string): DocumentTypeGroup } & {
    groupByName(groupName: string): DocumentTypeGroup;
};

type AliasHierarchies = Map<DocumentTypeGroup, FieldId[]>;

/**
 * For any DocumentTypeGroup enum used to group document types for an DocTypeAliasMetadataField on
 * the backend, there should be an analogous implementation of DocumentTypeGroups here. Another
 * entry should be added to the static map DocTypeAliasField.groupingTypeMap, where the key is the
 * name of the class and the value is the class itself. The string key should match
 * AliasHierarchyMap::getGroupingName() of whatever AliasHierarchyMap implementation used for the
 * field.
 */
export class DocTypeAliasField extends AliasingField {
    private static readonly groupingTypeMap: { [type: string]: GroupingClassType } = {
        PrimaryDateDocTypeGroup: PrimaryDateDocTypeGroup,
    };
    aliasHierarchies: AliasHierarchies;
    private groupingClass: GroupingClassType;
    override _mixin(params: any) {
        super._mixin(params);
        this.groupingClass = DocTypeAliasField.getGroupingClass(params.aliasHierarchies.grouping);
        this.aliasHierarchies = DocTypeAliasField.parseHierarchies(
            params.aliasHierarchies.map,
            this.groupingClass,
        );
        // For compatibility with AliasingField
        this.aliases = this.aliasHierarchies.get(this.groupingClass.getGroup("Other"));
    }

    static parseHierarchies(map: any, groupingClass: GroupingClassType): AliasHierarchies {
        const aliasHierarchies: AliasHierarchies = new Map<DocumentTypeGroup, FieldId[]>();
        Object.keys(map).forEach((groupName) => {
            aliasHierarchies.set(groupingClass.groupByName(groupName), map[groupName]);
        });
        return aliasHierarchies;
    }

    static getGroupingClass(grouping: string): GroupingClassType {
        const groupingClass = DocTypeAliasField.groupingTypeMap[grouping];
        if (groupingClass == null) {
            throw new Error(
                `Illegal argument passed to getGroupingClass(): ${grouping} does not correspond 
                to any grouping class for DocTypeAliasField.`,
            );
        }
        return groupingClass;
    }

    /** Obtains the Field[] corresponding to the given document's type. */
    getAliasedFields(doc: Document): Field[] {
        const group = this.groupingClass.getGroup(doc.type);
        return Base.get(Field, this.aliasHierarchies.get(group));
    }

    /** Using the Field[] representing the aliased field hierarchy, will return the first non-null val. */
    override docValue(doc: Document) {
        let cat = -1;
        let val: Value | null = null;
        this.getAliasedFields(doc).forEach((field) => {
            const oneVal = field.docValue(doc);
            if (field.category > cat && oneVal) {
                cat = field.category;
                val = oneVal;
            }
        });
        return val;
    }
    override getIconClass(): string {
        return "bolt-filled-blue-16";
    }
    override description(): string | undefined {
        // For new instances of this field, include descriptions here.
        if (this.name === DocTypeAlias.PRIMARY_DATE) {
            return (
                "The primary date for each document based on the ordering in the Project Settings "
                + "metadata tab"
            );
        }
        return null;
    }
}

// For now the only type of System fields we support are simply lists of aliased fields.
export class SystemField extends AliasingField {
    @ElevatedRoleConfirm("removing a system metadata field")
    removeAlias(field: Field, callback?: () => void, error?: () => void) {
        const a = this.getAliases();
        if (Arr.remove(a, field)) {
            Rest.post("metadata/removeFromSystemField.rest", {
                systemField: this.id,
                field: field.id,
            }).then(
                (data) => {
                    Base.set(Field, data);
                    callback && callback();
                },
                (e) => {
                    error && error();
                    throw e;
                },
            );
        } else {
            error && error();
        }
    }
}

const CATEGORY_TO_CLASS: {
    [category: string]: new (params: any, fromSubclass?: boolean) => Field;
} = {
    CONFLICT: ConflictField,
    SEMANTIC: SemanticField,
    ALIAS: AliasField,
    SYSTEM: SystemField,
    DOC_TYPE_ALIAS: DocTypeAliasField,
};

/**
 * Matches `OriginalMetadataValue.java#Source` enum.
 */
export enum OriginalSource {
    RESERVED = "RESERVED",
    LOADFILE = "LOADFILE",
    NATIVE_EXTRACTED = "NATIVE_EXTRACTED",
    TEXT_EXTRACTED = "TEXT_EXTRACTED",
}

export class Value extends Typed.Value {
    interpretedField: Field;
    originalSource?: OriginalSource;
    containsEditedValue: boolean;

    constructor(
        public field: Field,
        public override value: any,
        originalSource: OriginalSource = null,
        interpretedFieldId: number = null,
        containsEditedValue = false,
    ) {
        super();
        if (interpretedFieldId) {
            this.interpretedField = Base.get(Field, interpretedFieldId);
        }
        this.originalSource = originalSource;
        this.containsEditedValue = containsEditedValue;
    }

    getField(): Field {
        return this.field;
    }

    /**
     * The value to use when editing a user value.
     */
    editValue(): any {
        return this.getField().type.editValue(this.value);
    }
}

/**
 * Returns ComboBox.Completions on success, or a string message on error.
 */
export function autocomplete(
    field: Field,
    val: string,
    maxResults: number,
    includeNull: boolean,
    excludeList?: string[],
    kind?: Type.AddressTermKind,
) {
    if (field === null) {
        return Typed.emptyAutocomplete();
    }
    return Typed.autocomplete(val, {
        method: "POST",
        url: "search/autocompleteMetadata.rest",
        params: {
            val,
            field: field.id,
            maxResults,
            includeNull,
            excludeList,
            kind,
        },
    });
}

/**
 * Can the current user edit metadata?
 */
export function canEdit(override = User.Override.NONE) {
    return User.me.can(Perm.EDIT_METADATA, Project.CURRENT, override);
}

export function canCreate(): boolean {
    return User.me.can(Perm.ADMIN, Project.CURRENT, User.Override.ELEVATED);
}

export function getFamilyDateField(): FamilyDateVirtualField {
    return <FamilyDateVirtualField>(
        fieldByNameAndCategory(VirtualFieldKind.FAMILY_DATE, Category.VIRTUAL)
    );
}

/**
 * Mirrored in FamilyDate.java. We will not include Everlaw's "Family Date" field if there exists
 * another field (ALIAS or SEMANTIC) also named "Family Date". If there exists no such field, or if
 * it is invisible, we include Everlaw's "Family Date" field. For some cases, we may ignore
 * visibility of this other field.
 */
export function includeEverlawFamilyDateField(ignoreFieldVisibility: boolean): boolean {
    const field = fieldByName(VirtualFieldKind.FAMILY_DATE);
    if (ignoreFieldVisibility) {
        return !field;
    }
    return !field || !field.visible;
}
